/* Poppins Light */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/Poppins-Light.ttf') format('truetype');
  font-display: swap;
}

/* Poppins Thin Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  src: url('./fonts/Poppins-ThinItalic.ttf') format('truetype');
  font-display: swap;
}

/* Poppins Regular */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Poppins-Regular.ttf') format('truetype');
  font-display: swap;
}

/* Poppins Bold */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/Poppins-Bold.ttf') format('truetype');
  font-display: swap;
}

/* Poppins Extra Bold */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/Poppins-ExtraBold.ttf') format('truetype');
  font-display: swap;
}

/* Poppins Bold Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  src: url('./fonts/Poppins-BoldItalic.ttf') format('truetype');
  font-display: swap;
}

/* Poppins SemiBold Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  src: url('./fonts/Poppins-SemiBoldItalic.ttf') format('truetype');
  font-display: swap;
}

/* Poppins Medium Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  src: url('./fonts/Poppins-MediumItalic.ttf') format('truetype');
  font-display: swap;
}

/* Poppins Extra Bold Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  src: url('./fonts/Poppins-ExtraBoldItalic.ttf') format('truetype');
  font-display: swap;
}

/* Poppins Black Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  src: url('./fonts/Poppins-BlackItalic.ttf') format('truetype');
  font-display: swap;
}

/* Poppins Medium */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/Poppins-Medium.ttf') format('truetype');
  font-display: swap;
}

/* Poppins Light Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  src: url('./fonts/Poppins-LightItalic.ttf') format('truetype');
  font-display: swap;
}

/* Poppins Black */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  src: url('./fonts/Poppins-Black.ttf') format('truetype');
  font-display: swap;
}

/* Poppins SemiBold */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/Poppins-SemiBold.ttf') format('truetype');
  font-display: swap;
}

/* Poppins Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  src: url('./fonts/Poppins-Italic.ttf') format('truetype');
  font-display: swap;
}

/* Poppins Extra Light Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  src: url('./fonts/Poppins-ExtraLightItalic.ttf') format('truetype');
  font-display: swap;
}

/* Poppins Extra Light */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  src: url('./fonts/Poppins-ExtraLight.ttf') format('truetype');
  font-display: swap;
}

/* Poppins Thin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  src: url('./fonts/Poppins-Thin.ttf') format('truetype');
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif; /*Poppins?*/
}

.d-flex {
  display: flex
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.kb-progress-bar {
  position: relative;
  width: 100%;
  height: 8px;
}

.kb-progress-bar .filled-bar {
  height: 8px;
  position: absolute;
  background-color: #01B6E9;
  z-index: 3;
  border-radius: 4px;
}

.kb-progress-bar .empty-bar {
  height: 8px;
  position: absolute;
  background-color: #EAECF0;
  border-radius: 4px;
}

.boardName {
  position: absolute;
  top: 14px;
  left: 0;
  right: 0;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  z-index: -1;
}
.chevron_down{
  width: 19px;
  margin-left: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}
.reload_{
  width: 19px;
  margin-left: 5px;

  cursor: pointer;
}
.boardNameNew {
  color: #3D3D3D;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
}
.board_img {
  position: absolute;
  right: 20px;
  padding: 2px;
  top: 5px;
  cursor: pointer;
}

.Fixed_ {
  display: flex;
}

.fixed_bottom_collapsed {
  overflow-y: hidden;
}

.fixed_bottom_expanded {
  overflow-y: hidden;
}

.fixed_header_collapsed {
  position: fixed;
  padding: 5px;
  margin: 20px 20px 20px 100px;
  height: 45px;
  background-color: #F8FAFB;
  display: flex;
  align-content: flex-start;
  width: calc(100% - 50px);
  top: 0;
  z-index: 98;
}
.fixed_header_expanded {
  position: fixed;
  padding: 5px;
  margin: 20px 20px 20px 300px;
  height: 45px;
  background-color: #F8FAFB;
  display: flex;
  align-content: flex-start;
  width: calc(100% - 250px);
  top: 0;
  z-index: 98;
}

.fixed_botttom_collapsed {
  margin-left: 100px;
  margin-top: 100px;
  height:calc(100vh - 112px);
  transition: margin-left 0.3s;
}
.fixed_botttom_expanded {
  margin-left: 300px;
  margin-top: 100px;
  height:calc(100vh - 112px);
  transition: margin-left 0.3s;
}

.sidebar-container {
  position: fixed;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid #E7E7E7;
  z-index: 99;
  transition: width 0.3s;
}
.sidebar-top,
.sidebar-bottom {
  flex: 0 0 auto;
}
.sidebar-bottom {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.collapsed {
  width: 80px;
}
.expanded {
  width: 280px;
}
.header_logo_img_collapsed {
  padding: 24px;
  width: 32px;
  cursor: pointer;
}
.header_logo_img_expanded {
  width: 220px;
  padding: 19px 22px 21px 22px;
  cursor: pointer;
}
.home_button {
  padding: 20px 0px 40px;
  font-weight: bold;
  white-space: nowrap;
  flex: 1;
  overflow: hidden;
  border-bottom: none;
}
.home_button_seperator {
  height: 1px;
  width: 100%;
  margin-top: 20px;
  border-bottom: 1px solid #d0d5dd;
}
.home_button_img {
  padding-left: 31px;
  cursor: pointer !important;
}
.home_button_top {
  cursor: pointer !important;
}
.align_items_center {
  display: flex;
  align-items: center;
}

.expanded_text {
  padding: 10px 16px 10px 16px;
  cursor: pointer !important;
}
.expand_collapse {
  padding: 20px 31px 20px 31px;
  border-top: 1px solid #D0D5DD;
  border-bottom: 1px solid #D0D5DD;
  cursor: pointer;
}
.expand_collapse_button {
  border: none;
  background-color: white;
}
.getting_started_button_collapsed,
.sync_button_collapsed,
.help_button_collapsed,
.account_button_collapsed {
  padding: 20px 31px;
  cursor: pointer !important;
  transition: all 0.3s ease;
}
.logout_button_collapsed {
  padding: 20px 24px;
  cursor: pointer !important;
  transition: all 0.3s ease;
}
.account_button_collapsed,
.logout_button_collapsed {
  display: flex;
  align-items: center;
}
.getting_started_button_expanded,
.sync_button_expanded,
.help_button_expanded,
.account_button_expanded {
  padding: 10px 31px;
  cursor: pointer !important;
  width: 218px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.logout_button_expanded {
  padding: 10px 24px;
  cursor: pointer !important;
  width: 218px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.getting_started_button_expanded,
.account_button_expanded,
.logout_button_expanded {
   display: flex;
   justify-content: space-between;
   align-items: center;
}
.account_button_collapsed,
.account_button_expanded {
  margin-top: 24px;
  border-top: 1px solid #D0D5DD;
}
.logout_button_collapsed,
.logout_button_expanded {
  margin-bottom: 24px;
}

.goPremiumLeft {
  color: white;
  font-weight: bold;
  padding: 10px 16px;
  margin-left: 20px;
  margin-right: 20px;
  background: #01B6E9;
  border-radius: 7px;
  border: 0;
  cursor: pointer;
  transition: all 0.3s ease;
}
.goPremiumExpanded {
  height: 78px;
  text-align: left;
  cursor: pointer;
  position: relative;
}
.goPremiumLeftText {
  font-weight: normal;
  font-size: small;
  cursor: pointer;
}
.goPremiumLeftGlow:hover {
  color: var(--primary-200, #E9D7FE);
}
.goPremiumLeftTitle {
  cursor: pointer;
}
hr {
  border-radius: 7px;
  height: 8px;
  border: none;
}

.progress-bar-outer {
  width: 100%;
  height: 8px;
  background-color: white;
  border-radius: 7px;
  margin: 7px 0px;
}

.progress-bar-inner {
  height: 100%;
  max-width: 100%;
  background-color: #EE4E26;
  border-radius: 7px;
}

.go_premium_left_pointer_img { }

.intercom-launcher {
  background-color: #01B6E9 !important;
}
/* .intercom-launcher:hover svg path {
  fill: #f66e00 !important;
} */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: whitesmoke;
}
::-webkit-scrollbar-thumb {
  background: #aaaaaa !important ;
  border-radius: 10px;
}

.boardNameDiv{
  flex: 0 0 auto;
  align-self: center;
}
.laguage_bold_div {
  display: flex;
}
.laguage_bold_div > div {
  flex: 1;
  min-width: 180px;
}
.language_dropdown {
  flex: 0 0 auto;
  padding: 10px 16px 10px 16px;
  background-color: white;
  border: 1px solid #D0D5DD;
  border-radius: 7px;
  font-weight: bold;
  height: 18px;
  align-items: center;
  margin-left: 10px;
  flex: 1;
}
.dropdown-no-border {
  flex: 0 0 auto;
  padding: 10px 16px 10px 16px;
  font-weight: bold;
  height: 18px;
  align-items: center;
  margin-left: 10px;
}
.icon-dropdown-menu {
  flex: 0 0 auto;
  padding: 10px 16px 10px 16px;
  background-color: white;
  border: 1px solid #D0D5DD;
  border-radius: 7px;
  min-width: 46px !important;
  width: 46px !important;
}
.boldColors {
  padding-left: 10px;
}
.search {
  border: 1px solid #D0D5DD;
  background-color: white;
  border-radius: 7px;
  padding: 10px;
}
.searchGoPremium {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  padding: 0px 10px;
  height: 40px;
  margin-right: 60px;
}
.goPremiumTop {
  color: white;
  font-weight: bold;
  margin-left: 10px;
  padding: 10px 20px;
  background: linear-gradient(0deg, #EE4E26, #EE4E26),
    linear-gradient(0deg, #DF3217, #DF3217);
  border: 1px solid #DF3217;
  border-radius: 7px;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
}

.go_premium_top_pointer_img {
  padding-left: 10px;
  height: 18px;
}

.board_button {
  top: 11px;
}
button:disabled {
  opacity: 0.5 !important;
  cursor: none;
}
.toggle-switch-container {
  display: flex;
  align-items: center;
  border: 1px solid #D0D5DD;
  padding: 10px 16px 10px 16px;
  border-radius: 7px;
  background-color: white;
}

.toggle-switch-label {
  font-weight: bold;
  margin-left: 10px;
  color: #3D3D3D;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

.toggle-switch-checkbox {
  display: none;
}

.toggle-switch-slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 17px;
  cursor: pointer;
  transition: 0.4s;
}

.toggle-switch-slider:before {
  position: absolute;
  content: '';
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

.toggle-switch-checkbox:checked + .toggle-switch-slider {
  background-color: #01B6E9;
}

.toggle-switch-checkbox:checked + .toggle-switch-slider:before {
  transform: translateX(13px);
}

.boardCount {
  background-color: #01B6E9;
  color:white;
  font-weight: bold;
  width: 31px;
  height: 22px;
  padding: 2px 8px 2px 8px;
  margin: 0px 8px 0px 8px;
  border-radius: 16px;
}

.listModalContainer {
  top: 80px;
  left: 100px;
  /* width: 100%;
  height: 100%; */
  background-color: white;
  z-index: 9999;
  width: 280px;
  height: 420px;
  padding: 10px 16px 10px 16px;
  border-radius: 8px;
  border: 1px;
  gap: 4px;
  border: 1px solid #D1D1D1;
  box-shadow: 0px 10px 20px 0px #0000001A;
}
.listModalContainerExpanded {
  top: 80px;
  left: 300px;
  /* width: 100%;
  height: 100%; */
  background-color: white;
  z-index: 9999;
  width: 280px;
  height: 420px;
  padding: 10px 16px 10px 16px;
  border-radius: 8px;
  border: 1px;
  gap: 4px;
  border: 1px solid #D1D1D1;
  box-shadow: 0px 10px 20px 0px #0000001A;
}

.board-list-item {
  transition: background-color 0.4s;
}

.board-list-item:hover {
  background-color: #f6f6f6;
  border: 1px solid #D1D1D1;
  border-radius: 10px;
}

.board-list-item:hover .board-list-item-icon {
  opacity: 1;
}

/* .board-list-item-icon-container {
  display: flex;
} */

.board-list-item-icon {
  opacity: 0;
  margin-left: 10px;
  cursor: pointer;
  transition: color 0.3s, opacity 0.3s;
}

.board-list-item-icon:hover {
  color: #01B6E9;
}

*.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.account-panel-wrapper {
  position: absolute;
  z-index: 99;
  height: 100vh;

  background-color: var(--gray-50, #F9FAFB);
}

.account-panel-wrapper.expanded {
  margin-left: 280px;
  width: calc(100vw - 280px);
}

.account-panel-wrapper.collapsed {
  margin-left: 80px;
  width: calc(100vw - 80px);
}

.account-panel-close-icon {
  color: rgba(0,0,0,0.3);
  position: fixed;
  right: 50px;
  top: 50px;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
}

.account-panel-content {
  font-family: Poppins, sans-serif;
  color: var(--woodsmoke-900, #3D3D3D);
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  padding: 32px 32px 48px 32px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.account-panel-content .header {
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.6px;
}

.account-panel-content .subheader {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.32px;
}

.account-panel-content .tab-container {
  width: 100%;
  margin-top: 24px;
}

.account-panel-content .tab-header {
  display: flex;
  border-bottom: 1px solid var(--gray-200, #EAECF0);
}

.account-panel-content .tab-header .tab-title {
  margin-right: 20px;
  padding: 1px 4px 11px 4px;
  color: var(--gray-500, #667085);
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
}

.account-panel-content .tab-header .tab-title.selected {
  color: var(--picton-blue-500, #01B6E9);
  border-bottom: 2px solid var(--picton-blue-500, #01B6E9);
}

.account-panel-content .tab-header .tab-notification-count {
  color: var(--gray-700, #344054);
  margin: 0px 3px;
  padding: 2px 8px;
  border-radius: 16px;
  background: var(--gray-100, #F2F4F7);
}

.account-panel-content .tab-content {
  width: 100%;
  margin-top: 32px;

  color: var(--gray-600, #475467);
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.account-panel-content .tab-content .bold {
  color: var(--gray-700, #344054);
  font-weight: 600;
}

.account-panel-content .tab-content hr {
  height: 0px;
  border: 1px solid var(--gray-200, #EAECF0);
  margin: 20px 0px;
}

.account-panel-content .tab-content .tab-content-subheader {
  color: var(--gray-900, #101828);
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}

.account-panel-content .button-container {
  display: flex;
}

.account-panel-content .tab-content button.btn  {
  height: 40px;
  padding: 10px 16px;
  border-radius: 8px;
  transition: background-color 0.3s, border 0.3s;

  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;

  color: var(--gray-700, #344054);
  border: 1px solid var(--gray-300, #D0D5DD);
  background-color: var(--base-white, #FFF);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.account-panel-content .tab-content button.btn:hover {
  background-color: var(--gray-100, #F2F4F7);
}

.account-panel-content .tab-content button.btn.primary {
  color: var(--base-white, #ffffff);
  border: 1px solid var(--picton-blue-600, #009BD3);
  background-color: var(--picton-blue-500, #01B6E9);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.account-panel-content .tab-content button.btn.primary:hover {
  background-color: #7f56d9;
  border: 1px solid #643DB8;
}

.account-panel-content .user-avatar {
  width: 64px;
  height: 64px;
  object-fit: contain;
  border-radius: 50%;
  margin-right: 20px;
}

.account-panel-content .tab-content .drag-and-drop {
  flex-grow: 1;
  height: 126px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 12px;
  background-color: var(--base-white, #ffffff);
  padding: 16px 24px;
}

.account-panel-content .tab-content .mock-tab-content {
  /* Placeholder text/div props */
  height: 500px;
  background-color: rgba(0,225,255,0.08);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed rgba(0,0,0,0.3);
  border-radius: 15px;
  font-size: 36px;
}

.deleted-card {
  display: flex;
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF),
    linear-gradient(0deg, #E7E7E7, #E7E7E7);
  border: 1px solid #D0D5DD !important;
  width: 512px;
  padding: 16px;
  border-radius: 12px;
  border: 1px;
  gap: 10px;
  margin-top: 12px;
  cursor: pointer;
  transition: all 0.3s;
  min-height: 20px;
}
.deleted-card-left {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.deleted-card-title {
  font-weight: bold;
  flex: 1;
}
.deleted-item-header {
  margin-top: 30px;
}
.deleted-card:hover {
  border: 1px solid #01B6E9 !important;
}
.restored-card {
  border: 1px solid #01B6E9 !important;
  min-height: 40px;
  transition: all 0.3s;
}
.restored-card-label {
  font-family: Poppins, sans-serif;
  color: var(--picton-blue-500, #01B6E9);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.28px;
}
.restored-card-check {
  align-self: center;
}

.account-panel-content .tab-content .current-plan-card {
  margin: 32px;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid #EAECF0;
  background: #ffffff;
}

.account-panel-content .tab-content .current-plan-card .plan-cost {
  color: var(--gray-900, #101828);
  font-size: 48px;
  font-weight: 600;
}

.account-panel-content .tab-content .current-plan-card .upgrade-plan-button {
  color: var(--picton-blue-500, #01B6E9);
  font-weight: 500;
  margin-right: 12px;
  cursor: pointer;
}

.account-panel-content .tab-content .current-plan-card .progress-bar-container {
  font-weight: 600;
  margin-top: 24px;
}

.account-panel-content .tab-content .plan-selector-title {
  margin: 0 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.account-panel-content .tab-content .plan-selector-container {
  display: flex;
  justify-content: space-between;
  margin: 32px;
}

.account-panel-content .tab-content .plan-selector-container .plan-card {
  display: flex;
  padding: 16px;
  margin-left: 32px;
  margin-bottom: 12px;
  border-radius: 12px;
  border: 1px solid var(--gray-200, #EAECF0);
  background: var(--base-white, #FFF);
  cursor: pointer;
}

.account-panel-content .tab-content .plan-selector-container .plan-card .plan-icon-container {
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  color: #01B6E9;
  border-radius: 50%;
  border: 4px solid var(--picton-blue-100, #DEF5FF);
  background: var(--picton-blue-50, #EFFAFF);
  mix-blend-mode: multiply;
}

.account-panel-content .tab-content .plan-selector-container .plan-card .plan-checked {
  margin-left: 8px;
}

.account-panel-content .tab-content .plan-selector-container .plan-card.selected {
  color: var(--picton-blue-500, #01B6E9);
  border: 2px solid var(--picton-blue-500, #01B6E9);
  background: var(--picton-blue-50, #EFFAFF);
}

.account-panel-content .tab-content .ant-tabs-tab {
  background-color: initial;
  border: initial;
}

.account-panel-content .tab-content .ant-tabs-tab-active {
border: 1px solid #D0D5DD;
background-color: white;
}

.btn {
  text-transform: unset !important;
  display: flex !important;
  align-items: center;
}
.delete-account-card {
  display: 'flex';
  width: '100%';
  height:'220px';
  padding:'20px';
  background:'white';
}
.delete-account-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}
.delete-account-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}
.premium-modal {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  transition: background-color 0.3s;
}
.premium-modal-content {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  font-family: Poppins, sans-serif;
  font-style: normal;
  color: var(--woodsmoke-900, #3D3D3D);
  display: flex;
  transition: width 0.3s, height 0.3s;
}
.premium-modal-content .left {
  min-width:586px;
  display: flex;
  padding: 96px 0px;
  justify-content: flex-end;
  align-items: center;
  gap: 64px;
  flex: 1 0 0;
  align-self: stretch;
}
.premium-modal-content .left .inner-container {
  display: flex;
  padding: 0px 32px;
  align-items: flex-start;
  flex: 1 0 0;
}
.premium-modal-content .left .inner-content {
  display: flex;
  padding: 0px 32px 0px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  flex: 1 0 0;
}
.premium-modal-content .left .heading-check-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}
.premium-modal-content .left .heading {
  font-size: 48px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -0.96px;
}
.premium-modal-content .left .heading-check-items p {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.36px;
  margin: 0;
}
.premium-modal-content .left .heading-check-items ul {
  display: flex;
  padding-left: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
.premium-modal-content .left .heading-check-items li {
  display: flex;
  padding-left: 0px;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.36px;
}
.premium-modal-content .left .actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.premium-modal-content .left .actions button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.4px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  position: relative;
}
.premium-modal-content .left .actions .Yearly {
  border: 1px solid var(--picton-blue-600, #009BD3);
  background: var(--picton-blue-500, #01B6E9);
  color: var(--woodsmoke-50, #F6F6F6);
  padding: 16px 20px;
}
.premium-modal-content .left .actions .Monthly {
  border: 1px solid var(--woodsmoke-600, #5D5D5D);
  background: var(--basic-white, #FFF);
  color: var(--woodsmoke-500, #6D6D6D);
  padding: 12px 20px;
}
.premium-modal-content .left .actions .Yearly .badge {
  display: flex;
  padding: 2px 8px;
  align-items: center;
  position: absolute;
  right: 5px;
  top: 6px;
  border-radius: 6px;
  background: var(--pomegranate-500, #EE4E26);
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.28px;
}
.premium-modal-content .right {
  height: 776px;
  width: 100%;
  background-image: url('./resources/images/go-premium-bg.webp');
  background-color: lightgray;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.premium-modal-content .right .quote-container {
  display: flex;
  padding: 24px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  border: 1px solid rgba(255, 255, 255, 0.50);
  background: rgba(255, 255, 255, 0.30);
  backdrop-filter: blur(12px);
  position: absolute;
  bottom: 40px;
  right: 40px;
  left: 40px;
}
.premium-modal-content .right .quote {
  align-self: stretch;
  color: var(--base-white, #FFF);
  font-size: 36px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.72px;
}
.premium-modal-content .right .attribution-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
.premium-modal-content .right .attribution-container p {
  color: var(--base-white, #FFF);
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.36px;
  margin: 0;
}
.premium-modal-content .right .attribution-top {
  display: flex;
  align-items: center;
  gap: 16px;
}
.premium-modal-content .right .attribution-top .name {
  flex: 1 0 0;
  color: var(--base-white, #FFF);
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.6px;
}
.premium-modal-content .right .attribution-bottom {
  display: flex;
  gap: 12px;
  align-self: stretch;
}
.premium-modal-content .right .attribution-bottom .where-when {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1 0 0;
  min-height: 60px;
}
.premium-modal-content .right .attribution-bottom .arrows {
  display: flex;
  align-items: flex-start;
  gap: 32px;
}
.premium-modal-content .right .attribution-bottom button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  border: 1px solid rgba(255, 255, 255, 0.50);
  width: 56px;
  height: 56px;
  transition: background-color 1s ease;
  background-color: transparent;
}
.premium-modal-content .right .attribution-bottom button .fa-icon {
  transition: color 1s ease;
  color: white;
}
.premium-modal-content .right .attribution-bottom button:hover {
  background-color: white;
}
.premium-modal-content .right .attribution-bottom button:hover .fa-icon {
  color: black;
}
.premium-modal-content .close-btn {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 18px;
  top: 16px;
  border: 0;
  cursor: pointer;
  border-radius: 6px;
  transition: background-color 0.3s ease;
  background-color: transparent;
}
.premium-modal-content .close-btn:hover {
  background-color: rgba(255, 255, 255, 0.25);
}


.premium-modal-content.get-started > div {
  width: 100%;
  height: 100%;
  transition: opacity 0.3s;
}

.premium-modal-content .media-step .media-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
}

.premium-modal-content .media-step .text-wrapper {
  padding: 24px;
}

.premium-modal-content .media-step .get-started-title-sm {
  color: var(--woodsmoke-900, #3D3D3D);
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.premium-modal-content .media-step .get-started-text-sm {
  color: var(--woodsmoke-900, #3D3D3D);
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  white-space: pre-wrap;
}

.premium-modal-content .media-step .get-started-text-sm a {
  text-decoration-line: underline;
}

.premium-modal-content.get-started hr {
  height: 1px;
  border-bottom: 1px solid var(--gray-200, #EAECF0);
  padding: 0;
  margin: 0;
  border-radius: 0;
}

.premium-modal-content.get-started .button-container {
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.premium-modal-content.get-started .button-container button {
  flex-grow: 1
}

.premium-modal-content.get-started .btn-light {
  color: var(--woodsmoke-900, #3D3D3D);
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 18px;

  border-radius: 8px;
  border: 1px solid var(--woodsmoke-900, #3D3D3D);
  background: var(--base-white, #FFF);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.premium-modal-content.get-started .btn-blue {
  color: var(--woodsmoke-50, #F6F6F6);
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 18px;

  border-radius: 8px;
  border: 1px solid var(--picton-blue-600, #009BD3);
  background: var(--picton-blue-500, #01B6E9);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.premium-modal-content.get-started .dot-nav-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.premium-modal-content.get-started .dot-nav {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 24px;
}

.premium-modal-content.get-started .dot-nav div {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transition: color 0.2s, transform 0.2s;
  cursor: pointer;
}

.premium-modal-content .tutorial-wrapper {
  height: 100%;
  display: flex;
}

.premium-modal-content .tutorial-wrapper > div {
  width: 50%;
}

.premium-modal-content .tutorial-wrapper .tutorial-nav {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;

  color: var(--woodsmoke-900, #3D3D3D);
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.premium-modal-content .tutorial-wrapper .tutorial-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.premium-modal-content .tutorial-wrapper .text-wrapper {
  padding: 24px;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.premium-modal-content .tutorial-wrapper .text-wrapper .tutorial-title {
  color: var(--basic-black, #0F0F0F);
  font-family: Poppins, sans-serif;
  font-size: 48px;
  font-weight: 600;
}

.premium-modal-content .tutorial-wrapper .text-wrapper .tutorial-text {
  color: var(--woodsmoke-900, #3D3D3D);
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.firebase-auth {
  width: 100%;
  max-width: 400px;
}

.checkbox-label {
  color: var(--woodsmoke-500, #6D6D6D);
font-family: Poppins, sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 142.857% */
letter-spacing: -0.28px;

}

.checkbox-label > input {
  margin-right: 10px;
}
